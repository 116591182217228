// @import url(./variables.scss);
// @import url(./fonts.scss);
@import "variables";
@import "fonts";

html {
  position: relative;
  width: 100%;
}

html[dir="rtl"] {
  position: relative !important;
  width: 100%;
  overflow-x: hidden;
}

body {
  position: relative !important;
  font-family: var(--regular);
  font-style: normal;
  font-size: 15px;
  background-color: var(--white);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--regular);
}
body.no-scroll {
  overflow: hidden;
}

.bg-lightRed {
  background: #fff5f5 !important;
}

.bg-lightYellow {
  background: #fff7ed !important;
}

.bg-lightGreen {
  background: #edf7f8 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--greyText);
  letter-spacing: 0.5px;
  margin-top: 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

img {
  width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

p {
  color: var(--greyText);
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 6px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

a {
  text-decoration: none;
}

a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

*::-moz-selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;
}

*::-moz-selection {
  background: var(--themeColor) none repeat scroll 0 0;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:hover::-webkit-inner-spin-button,
input:hover::-webkit-outer-spin-button {
  display: none;
}

input::-moz-inner-spin-button,
input::-moz-outer-spin-button {
  -moz-appearance: none;
  margin: 0;
}

input:hover::-moz-inner-spin-button,
input:hover::-moz-outer-spin-button {
  display: none;
}

.custom-scroll::-webkit-scrollbar {
  width: 3px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #888;
  transition: all 0.4s ease;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.heading_wapper {
  padding: 20px 40px 95px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

.heading_wapper::-webkit-scrollbar {
  // display: none;
  width: 4px;
}

.heading_wapper::-webkit-scrollbar-track {
  background: var(--greyBg);
  /* color of the tracking area */
}

.heading_wapper::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

.heading_wapper_content {
  width: 80%;
  // height: 385px;
}

.badge span {
  font-size: 12px !important;
  font-weight: normal !important;
  font-family: var(--regular);
}

.badge.bg-orange {
  background-color: var(--lightYellow) !important;
  color: var(--orange);
}

.badge.bg-blue {
  background-color: var(--lightBlue1) !important;
  color: var(--themeColor);
}

.badge.bg-purple {
  background-color: var(--lightPurple) !important;
  color: var(--purple);
}

.badge.bg-green {
  background-color: var(--lightGreen1) !important;
  color: var(--green2);
}

.badge.bg-red {
  background-color: var(--red) !important;
  color: var(--white);
}

.bg-darkGreen {
  background-color: var(--green) !important;
  color: var(--white);
}

.bg-darkOrange {
  background-color: var(--orange) !important;
  color: var(--white);
}

.bg-blue {
  background-color: var(--themeColor) !important;
}

.text-white {
  color: var(--white);
}

.text-blue {
  color: var(--themeColor);
}

.text-darkBlue {
  color: var(--darkBlue);
}

.text-green {
  color: var(--green2);
}

.text-grey {
  color: var(--grey);
}

.text-greyText {
  color: var(--greyText);
}

.text-orange {
  color: var(--orange);
}

.text-red {
  color: var(--red);
}

.text-purple {
  color: var(--purple);
}

ul.actionsList {
  gap: 5px;
}

ul.actionsList li button {
  padding: 0;
}

.form-floating input,
.custom-select-design {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 13px !important;
  color: var(--greyText) !important;
  font-weight: 400 !important;
  border-radius: 8px !important;
  border: none;
  background-color: var(--greyBg);
  height: 43px;
}

.med-sel {
  // height: 38px;
  padding: 0px 13px 0 15px !important;
}

.button_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // width:90%;
  & > button {
    margin: 25px 0 15px 0;
  }
}

.formLabel {
  display: block;
  color: var(--darkBlue);
  font-family: var(--semiBold);
  font-size: 16px;
  margin: 5px 0;
}

.badge {
  display: flex;
  // justify-content: space-between;
}

.site_Logo {
  display: flex;
  align-items: center;
  gap: 50px;
}

.dropdown-menu {
  padding: 0;
  overflow: hidden;
}

.dropdown-divider {
  margin: 0;
}

.dropdown-menu .dropdown-item:last-child hr {
  display: none;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--greyBg);
}

.back_arrow {
  cursor: pointer;
  transform: scale(-1);
}

.userNameT {
  position: relative;
  color: var(--darkBlue);
  font-size: 14px;
  font-family: var(--medium);
  padding-right: 20px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: flex;
    width: 12px;
    height: 7px;
    background-image: url(../images/svg/dropArrow.svg);
    background-repeat: no-repeat;
  }
}

.boxTVB {
  display: flex;
  align-items: center;
  gap: 20px;
}

.boxRBt {
  display: flex;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
}

.main-content {
  padding: 18px;
}

.ProgressBarContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ProgressBarContainer.tbl-graph {
  height: 40px;
  overflow: unset;
}
.graphTileF .ProgressBarContainer.tbl-graph {
  height: 65px;
  width: 65px;
  position: relative;
  left: -7px;
  top: -11px;
}
.col-tile-graph .TiledWrapper {
  align-items: flex-start !important;
}

.ProgressBarContainer .apexcharts-legend {
  padding-right: 0 !important;
  // padding-left: 5px !important;
}

.ProgressBarProject > div {
  margin: 5px 0 0 -13px;
}

// .apexcharts-legend-series{
//     margin-left: 0 !important;
// }
// .apexcharts-canvas{
//     height: 200px !important;
// }
.pBarTitle {
  position: absolute;
  font-family: var(--semiBold);
  font-size: 14px;
  color: var(--darkBlue);
  top: 7px;
  left: 107px;
}

.pBarCompleted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 23px;
  width: 60px;
  height: 60px;
  flex-direction: column;
}

.pBarCompleted > span {
  width: 100%;
  text-align: center;
}

.pBarCompleted > span.pBarComTitle {
  font-family: var(--semiBold);
  font-size: 14px;
  color: var(--darkBlue);
}

.pBarCompleted > span.pBarComText {
  font-family: var(--regular);
  font-size: 9px;
  color: var(--grey);
  padding-bottom: 7px;
}

// .tpbspc{
//     min-width: 470px !important;
//     max-width: 470px !important;
// }
.ProgressBarProjectCost {
  .apexcharts-legend {
    flex-direction: row !important;
    gap: 0px !important;
    flex-wrap: wrap;
    left: 110px !important;
    align-items: baseline;
  }

  .apexcharts-legend-series {
    display: flex;
    align-items: center;
    margin-right: 15px !important;
  }

  .apexcharts-legend-text {
    white-space: nowrap;
  }

  .apexcharts-legend-marker {
    top: 0 !important;
  }
}

.apexcharts-legend-marker {
  width: 10px !important;
  height: 10px !important;
  top: 0 !important;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    margin: auto;
    width: 14px;
    height: 14px;
    border: 1px solid var(--themeColor);
    padding: 6px;
    border-radius: 4px;
  }
}

.apexcharts-legend-text {
  margin-left: -10px !important;
}

.dropdown-menu:not(.dropLeft) {
  position: absolute;
  width: max-content !important;
  inset: 0 0 auto auto !important;
  transform: translate3d(0px, 45px, 0px) !important;
  -webkit-transform: translate3d(0px, 45px, 0px) !important;
  -moz-transform: translate3d(0px, 45px, 0px) !important;
  -ms-transform: translate3d(0px, 45px, 0px) !important;
  -o-transform: translate3d(0px, 45px, 0px) !important;
}

.MNHeading {
  line-height: 20px;
  padding: 10px 15px 10px 15px;
  border-bottom: 1px solid var(--outlineC);
}

// .ProgressBarProjectCost .apexcharts-legend{
//     flex-direction: row !important;
//     gap: 0px !important;
//     flex-wrap: wrap;
//     left: 110px !important;
//     align-items: baseline;
// }
// .ProgressBarProjectCost .apexcharts-legend-series{
//     display: flex;
//     align-items: center;
//     margin-right: 20px !important;
// }
// .ProgressBarProjectCost .apexcharts-legend-text{
//     white-space: nowrap;
// }
// .ProgressBarProjectCost .apexcharts-legend-marker{
//     top: 0 !important;
// }
.row-tile-4 .TileProgressBar {
  min-width: 270px;
  max-width: 270px;
}
.row-tile-33 .TileProgressBar {
  min-width: 350px;
  max-width: 350px;
}
.row-tile .col-prog-tile {
  width: fit-content;
  flex: none;
}
.row-tile-33 .TileProgressBar .pBarTitle {
  font-size: 13px !important;
  color: var(--greyText) !important;
  line-height: 16px !important;
  font-family: var(--regular);
  top: 17px;
}
.extDataCount {
  position: absolute;
  color: var(--darkBlue);
  font-family: var(--semiBold);
  line-height: 18px;
  top: 53px;
  left: 120px;
}

.row-tile-3 .TileProgressBar,
.row-tile-2 .TileProgressBar {
  min-width: 470px !important;
  max-width: 470px !important;
}
.row-tile-full .col-prog-tile {
  width: 100%;
}
.row-tile-full .TileProgressBar {
  min-width: 100% !important;
  max-width: 100% !important;
}
.row-tile-full .ProgressBarProject > div {
  width: 100%;
}
.row-tile-full .ProgressBarProject .apexcharts-canvas {
  width: 100% !important;
}
.row-tile-full .ProgressBarProject .apexcharts-canvas .apexcharts-svg {
  width: 100% !important;
}
.row-tile-full
  .ProgressBarProject
  .apexcharts-canvas
  .apexcharts-svg
  foreignObject {
  width: 100% !important;
}
.row-tile-full
  .ProgressBarProject
  .apexcharts-canvas
  .apexcharts-svg
  foreignObject
  .apexcharts-legend {
  width: 100% !important;
  left: 140px !important;
  top: 47px !important;
}
.row-tile-full .col-prog-tile .pBarCompleted {
  width: 83px;
  height: 83px;
}

.graph-sel-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.graph-sel-h-col {
  display: flex;
  flex-wrap: wrap;
}

.mo-duration {
  display: flex;
  width: 100%;
  gap: 5px;
}

.per-graph > div {
  margin-left: -25px;
  display: flex;
  align-items: center;
}

.tbl-graph > div {
  margin-left: 0;
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.pro-tas-list > div {
  box-shadow: none !important;
  border: 1px solid var(--outlineC) !important;
}

// .noWrap-tile-4{
//     flex-wrap: nowrap;
// }
// .col-tile-4{
//     width: 100%;
//     max-width: 200px;
//     flex: none;
// }

.perBarCompleted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
  flex-direction: column;
  margin: auto;
  right: 0;
}

.perBarCompleted > span {
  width: 100%;
  text-align: center;
}

.perBarCompleted > span.perBarComTitle {
  font-family: var(--semiBold);
  font-size: 25px;
  color: var(--darkBlue);
}

.perBarCompleted > span.perBarComText {
  font-family: var(--regular);
  font-size: 14px;
  color: var(--grey);
  padding-bottom: 7px;
}

.cross {
  display: none;
}

.menuClosed .cross {
  display: block;
}

.menuClosed .icon-trigger {
  display: none;
}

.sidebar.menuClosed {
  width: 67px;
  min-width: 67px;
  overflow: hidden;
}

.sidebar.menuClosed {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  padding-right: 10px;
  padding-left: 10px;
}

.sidebar.menuClosed .sidebar-list a span.menu-name {
  opacity: 0;
  visibility: hidden;
}

.sidebar.menuClosed .logoSidebar {
  display: none;
}

body.bodyClosed .topBar,
body.bodyClosed .secondaryBar {
  padding-left: 87px;
}

body.bodyClosed .main-content-container {
  padding-left: 67px;
}

body.bodyClosed .sidebar-list {
  padding: 0px 0px 0px 0px;
}

body.bodyClosed a {
  padding: 8px 12px;
}

.border-bottom-custom {
  border-bottom: 1px solid var(--outlineC);
}

.filterWrap {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 14px;
    color: var(--grey);
  }
}

.secondaryBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 2px 0 0;
  gap: 10px;
}

.SearchBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border-bottom: 1px solid var(--outlineC);
}

.contact {
  width: 390px;
}

.contact-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contact-bar > select {
  width: 200px;
}

.form-floating input,
.custom-select-design {
  border: none;
  font-size: 13px !important;
  color: var(--greyText) !important;
  font-weight: 400 !important;
  border-radius: 8px !important;
  // width: fit-content;
  background-color: var(--greyBg);
  height: 43px;
}

.form-floating textarea {
  font-size: 13px !important;
  color: var(--greyText) !important;
  border-radius: 8px !important;
  border: none;
  background-color: var(--greyBg);
}

.form-floating textarea:focus {
  border: 1px solid var(--greyText) !important;
}

.form-floating input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  // border: 1px solid transparent !important;
}

.form-floating input:focus {
  box-shadow: none !important;
  border-color: var(--greyText) !important;
  border: 1px solid var(--greyText) !important;
}

.form-floating input:focus,
.form-floating .floating-input {
  box-shadow: none !important;
  // border-color: var(--greyText) !important;
  // border: 1px solid var(--themeColor) !important;
}

.floating-input.icon-float,
.floating-input.icon-float ~ label:not([value]) {
  padding-left: 46px !important;
}

.floating-input.icon-security {
  padding-right: 40px !important;
}

.form-floating .floating-input ~ label {
  top: -4px !important;
  font-size: 13px !important;
  height: 100%;
  color: var(--greyText) !important;
  transition: 0.2s ease-in-out all;
  width: -moz-fit-content;
  width: fit-content;
}

.form-floating .floating-input:not(:-moz-placeholder-shown) ~ label {
  top: -2px !important;
  background-color: var(--white);
  font-size: 13px !important;
  opacity: 1 !important;
  height: auto !important;
  padding: 0px 7px 0px 15px !important;
}

.form-floating .floating-input:not(:placeholder-shown) ~ label,
.form-floating .floating-input:focus ~ label {
  top: -2px !important;
  background-color: var(--white);
  font-size: 13px !important;
  opacity: 1 !important;
  height: auto !important;
  padding: 0px 7px 0px 15px !important;
}

.form-floating svg {
  top: 12px;
  left: 15px;
  z-index: 1;
}

.form-floating svg [stroke] {
  stroke: var(--greyText);
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(2.5rem + calc(var(--bs-border-width) * 2)) !important;
  line-height: 1.25;
}

.custom-select-design {
  // height: 45px;
  // width: -webkit-fill-available;
  padding-left: 15px;
  padding-right: 40px;
  border-color: var(--greyText) !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../images/svg/arrow-down.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: right 15px center;

  &:not(.no-icon) {
    padding-left: 15px;
  }

  &.no-icon {
    padding-left: 45px;
  }
}

.custom-select-design:focus-visible {
  border: 1px solid var(--greyText) !important;
  outline: none !important;
}

.searchBtn {
  flex: none;
  width: fit-content;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.med-sel {
  // height: 38px;
  padding: 0px 40px 0 15px !important;
  width: 250px;

  &:not(.no-icon) {
    padding-left: 45px !important;
  }

  &.no-icon {
    padding-left: 18px !important;
  }
}

.radioListWrapper {
  gap: 100px;
}

.radioList {
  margin: 5px 0 5px 0;
}

.radioList > div {
  display: flex;
  gap: 15px;
}

.inputFieldBox {
  display: flex;
  width: 100%;
  margin: 15px 0;
}

.avatarIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background: var(--green);
  cursor: pointer;
}

.downloadbtn {
  border: 1px solid var(--green);
  background: transparent !important;
  cursor: pointer;
}

.members-popup .modal-dialog {
  width: 400px;
}

.re-scedule-meeting-modal .modal-dialog {
  max-width: 700px;
}

.re-scedule-meeting-modal .modal-content {
  border-radius: 14px;
  background: var(--white, #fff);
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.15);
  border: 0;
}

.re-scedule-meeting tbody td {
  padding: 10px 15px;
}

.img-fluid {
  border-radius: 50%;
}

.members-popup .modal-content {
  color: var(--white) !important;
  pointer-events: auto;
  background-color: var(--darkBlue) !important;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--darkBlue) !important;
}

.spnDfIc {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 15px;
}

.formBtns {
  display: flex;
  gap: 10px;
  padding: 15px 20px;
  justify-content: flex-end;
  border-top: 1px solid var(--greyBg);

  button {
    min-width: 120px;
    width: fit-content;
  }

  &.formBtnsInner {
    padding: 15px 0px;
    justify-content: flex-end;
  }
}

.card-styled-header .tile-icon {
  width: auto !important;
  height: auto !important;
  background-color: transparent !important;
  border-radius: 0 !important;
}
.messages {
  display: none !important;
}
.clender-range {
  background: transparent !important;
  &::placeholder {
    color: var(--themeColor);
  }
}

/***************TimePicker*****************/
.react-ios-time-picker-popup .react-ios-time-picker {
  margin: auto !important;
}
.react-ios-time-picker-popup .react-ios-time-picker-btn-container {
  background-color: var(--white);
  border-bottom: 1px solid #dee2e6;
}
.react-ios-time-picker-popup .react-ios-time-picker-container {
  background-color: var(--white);
}
.react-ios-time-picker-popup .react-ios-time-picker {
  box-shadow: 0 11px 15px #0005;
}
.react-ios-time-picker-popup .react-ios-time-picker-selected-overlay {
  background-color: var(--themeColor);
}
.react-ios-time-picker-popup .react-ios-time-picker-cell-inner-hour:hover,
.react-ios-time-picker-cell-inner-minute:hover,
.react-ios-time-picker-cell-inner-hour-format:hover {
  background-color: var(--themeColor);
  color: white;
}
.react-ios-time-picker-popup .react-ios-time-picker-btn {
  color: var(--themeColor);
}

.calendarStartDate .react-calendar button.react-calendar__navigation__arrow {
  font-size: 22px;
  color: var(--themeColor);
}
.calendarStartDate .react-calendar button.react-calendar__navigation__label {
  font-size: 20px;
  color: var(--grey);
  font-family: var(--medium);
}
.calendarStartDate
  .react-calendar
  .react-calendar__month-view__weekdays__weekday {
  font-size: 14px;
  color: var(--darkBlue);
  font-family: var(--semiBold);
}
.calendarStartDate
  .react-calendar
  .react-calendar__month-view__weekdays__weekday--weekend {
  font-size: 14px;
  color: var(--red);
  font-family: var(--semiBold);
}
.calendarStartDate .react-calendar abbr[title] {
  text-decoration: none;
  cursor: default;
  text-decoration-skip-ink: none;
}
.calendarStartDate
  .react-calendar
  .react-calendar__month-view__days__day--neighboringMonth {
  color: var(--grey);
}

.react-datepicker {
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: var(--themeColor);
    border-width: 2px 2px 0 0;
    width: 9px;
    height: 9px;
    top: unset;
    left: 0;
    right: 0;
  }

  .react-datepicker__navigation-icon {
    top: 0;
    left: 0;
    right: 0;
    width: 13px;
    height: 13px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 1.9rem;
    line-height: 1.9rem;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: var(--themeColor);
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: var(--themeColor);
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    color: var(--white);
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: -5px;
    margin-bottom: 5px;
  }
}
.modal-dialog.modal-xxl {
  min-width: calc(100% - 40px);
  min-height: auto;
  height: auto;
}
.modal-dialog.modal-xxl .modal-body {
  padding: 0;
}
.rangeIconText {
  svg {
    [stroke] {
      stroke: var(--grey);
    }
    [fill] {
      fill: var(--grey);
    }
  }
}
table tr td {
  background-clip: padding-box;
}
/********************direction rtl*****************/
// .card-styled-header .tile-icon {
//     width: auto !important;
//     height: auto !important;
//     background-color: transparent !important;
//     border-radius: 0 !important;
// }

// .messages {
//     display: none !important;
// }

// .clender-range {
//     background: transparent !important;

//     &::placeholder {
//         color: var(--themeColor);
//     }
// }

// .react-ios-time-picker {
//     margin: auto !important;
// }

// .react-ios-time-picker-btn-container {
//     background-color: var(--white);
//     border-bottom: 1px solid #dee2e6;
// }

// .react-ios-time-picker-container {
//     background-color: var(--white);
// }

// .react-ios-time-picker {
//     box-shadow: 0 11px 15px #0005;
// }

// .react-ios-time-picker-selected-overlay {
//     background-color: var(--themeColor);
// }

// .react-ios-time-picker-cell-inner-hour:hover,
// .react-ios-time-picker-cell-inner-minute:hover,
// .react-ios-time-picker-cell-inner-hour-format:hover {
//     background-color: var(--themeColor);
//     color: white;
// }

// .react-ios-time-picker-btn {
//     color: var(--themeColor);
// }

.searchWrapper {
  font-size: 13px !important;
  color: var(--greyText) !important;
  font-weight: 400 !important;
  border-radius: 8px !important;
  border: 1px solid transparent !important;
  background-color: var(--greyBg) !important;
  padding: 8px 10px !important;
}

.optionListContainer {
  z-index: 5;
}

.multiSelectContainer input[type="text"] {
  width: auto !important;
}

.chip {
  border-radius: 6px;
  background-color: var(--themeColor) !important;
}

.multiSelectContainer li:hover {
  background-color: var(--themeColor) !important;
}

.searchWrapper {
  font-size: 13px !important;
  color: var(--greyText) !important;
  font-weight: 400 !important;
  border-radius: 8px !important;
  border: 1px solid transparent !important;
  background-color: var(--greyBg) !important;
}

.optionListContainer {
  z-index: 5;
}

.multiSelectContainer input[type="text"] {
  width: auto !important;
}

.chip {
  border-radius: 6px;
  background-color: var(--themeColor) !important;
}

.multiSelectContainer li:hover {
  background-color: var(--outlineC) !important;
  color: var(--greyText);
}

.multiSelectContainer li {
  color: var(--greyText) !important;
  display: flex;
  align-items: flex-start;
}

.card-styled-header .tile-icon {
  width: auto !important;
  height: auto !important;
  background-color: transparent !important;
  border-radius: 0 !important;
}

.messages {
  display: none !important;
}

.clender-range {
  background: transparent !important;

  &::placeholder {
    color: var(--themeColor);
  }
}

.multiSelectContainer .optionContainer .option input[type="checkbox"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid var(--greyText);
  transform: translateY(-0.075em);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.multiSelectContainer .optionContainer .selected input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 13px;
  height: 13px;
  background: var(--themeColor);
  transform: translateY(-0.075em);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.multiSelectContainer
  .optionContainer
  .selected
  input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 18px;
  height: 18px;
  border: 1px solid var(--themeColor);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.agenda {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.bTNS {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.radio-design {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  // background-color: var(--lightPurple);
  // border: 1px solid var(--purple);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.option-all {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  background-color: var(--outlineC);
  border: 1px solid var(--grey);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.option-Standalone {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  background-color: var(--lightGreen1);
  border: 1px solid var(--green2);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.option-Relational {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  background-color: var(--lightPurple);
  border: 1px solid var(--purple);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.check-design {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid var(--grey);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.check-design-inner {
  width: 13px;
  height: 13px;
  background-color: var(--grey);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.view-project-list {
  border: none !important;
  background: var(--greyBg) !important;
  width: 250px !important;
  padding: 8px 12px !important;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
}

// .box-check{
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     width: 18px;
//     height: 18px;
//     background-color: var(--white);
//     border: 1px solid var(--grey);
//     border-radius: 5px;
//     -webkit-border-radius: 5px;
//     -moz-border-radius: 5px;
//     -ms-border-radius: 5px;
//     -o-border-radius: 5px;
//     &:after{
//         content: '';
//         width: 14px;
//         height: 14px;
//         background-color: var(--grey);
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         margin: auto;
//         border-radius: 5px;
//         -webkit-border-radius: 5px;
//         -moz-border-radius: 5px;
//         -ms-border-radius: 5px;
//         -o-border-radius: 5px;
//     }
// }
// .box-check.boxThemeColor{
//     border: 1px solid var(--themeColor);
//     &:after{
//         background-color: var(--themeColor);
//     }
// }

/********************direction rtl*****************/

body.rtl {
  font-family: var(--arRegular);
}

body.rtl {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    right: -9999px;
  }
}

body.rtl .vector_right {
  right: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

body.rtl .vector_left {
  left: -100px !important;
  right: auto;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

body.rtl .back_arrow {
  transform: scale(1);
}

body.rtl .btnWapper {
  left: 20px;
  right: auto;
  z-index: 10000;
}
