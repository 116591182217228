.pBarContainer{
	padding: 40px 80px 70px 80px;
	border-bottom: 1px solid var(--outlineGreyBG);
}
// .formDataContainer{
// 	padding: 20px;
// }
.RSPBprogressBar {
	height: 3px;
	line-height: 1;
	border-radius: 10px;
	position: relative;
	background-color: var(--outlineGreyBG);
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 0;

	
	.RSPBstep {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		z-index: 0;
		margin-top: 28px;
		// position: absolute;
		// transform: translateX(-50%);
		// transition-property: all;
		// transition-timing-function: ease;
		&:first-child{
			margin-left: -70px;
		}
		&:nth-child(4){
			margin-right: -70px;
		}
	}
	
	.RSPBprogressBarText {
		color: white;
		font-size: 10px;
		// position: absolute;
		// top: 50%;
		// left: 50%;
		// transform: translate(-50%, -50%);
	}
	.RSPBprogression {
		position: absolute;
		transition: width 0.3s ease;
		left: 0;
		top: 0;
		bottom: 0;
		border-radius: 10px;
		background: rgba(0, 116, 217, 0.8);
		z-index: -1;
	}
	.indexedStep {
		color: white;
		width: 140px;
		// height: 20px;
		font-size: 12px;
		
		border-radius: 50%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 14px;
		color: var(--grey);
	}
	.indexedCount{
		background-color: var(--white);
		border-radius: 50%;
		width: 38px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 3px solid var(--outlineC);
	}
	.accomplished .indexedCount{
		background-color: var(--themeColor);
		color: var(--white);
		border: 3px solid var(--themeColor);
	}
	.stepTitle{
		width: 100%;
		margin-top: 15px;
		font-family: var(--semiBold);
	}
	.accomplished .stepTitle{
		color: var(--themeColor);
	}
	
	// .indexedStep.accomplished {
	// 	background-color: rgba(0, 116, 217, 1);
	// }
	
}

