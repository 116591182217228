:root {
  --themeColor: #018de4;
  --darkBlue: #222a5a;
  --lightBlue: #eff3ff;
  --lightBlue1: #eff9ff;
  --green: #16a4b0;
  --white: #ffffff;
  --grey: #92929d;
  --greyText: #b1b1bc;
  --greyBg: #f6f8fc;
  --outlineC: #f1f1f5;
  --outlineGreyBG: #e6e6ef;
  --lightYellow: #fff7ed;
  --orange: #fe9a02;
  --lightRed: #fff5f5;
  --red: #e8374f;
  --lightPurple: #f4edff;
  --purple: #8851be;
  --lightGreen: #edf7f8;
  --lightGreen1: #f1fcf0;
  --green2: #0fbc00;
}
